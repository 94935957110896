import React, { useState } from 'react';
import { ActionIcon, Button, Text, Textarea } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import env from 'env';
import axios from 'api/axiosConfig';
import { transcriptionCacheAtom } from 'pages/editorv2/atoms';
import { notifications } from '@mantine/notifications';

const TranscriptSummary = ({
  candidateId,
  campaignId,
  summary,
  callId,
  showEditButton = true,
}) => {
  const [, setTranscriptionCache] = useAtom(transcriptionCacheAtom);

  const cacheKey = `${candidateId}_${campaignId}`;

  const [inputSummary, setInputSummary] = useState(summary);
  const [editSummary, setEditSummary] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleClickEditSummary = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setEditSummary(true);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputSummary(e.currentTarget.value);
  };

  const handleCancelEditSummary = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setInputSummary(summary);
    setEditSummary(false);
  };

  const handleSaveSummary = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!callId) {
      console.error('Call ID not found');
      return;
    }

    try {
      setIsSaving(true);
      await axios.patch(`${env.REACT_APP_SERVER_URL}/call/${callId}/summary`, {
        summary: inputSummary,
      });

      if (candidateId && campaignId) {
        setTranscriptionCache({
          data: {
            [cacheKey]: { summary: inputSummary },
          },
          timestamp: Date.now(),
        });
      }

      setEditSummary(false);
      notifications.show({
        title: 'Summary saved',
        message: 'Summary has been saved successfully',
        color: 'green',
      });
    } catch (error) {
      console.error('Error saving summary to cache:', error);
      notifications.show({
        title: 'Error saving summary',
        message: 'Failed to save summary',
        color: 'red',
      });
    } finally {
      setIsSaving(false);
    }
  };

  if (!summary) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
        <Text size='sm' fw='500'>
          Summary
        </Text>
        {!editSummary && showEditButton && (
          <ActionIcon
            variant='subtle'
            color='blue'
            onClick={handleClickEditSummary}
          >
            <IconEdit size='14px' />
          </ActionIcon>
        )}
      </div>
      {editSummary ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Textarea
            autosize
            value={inputSummary}
            onChange={handleOnChange}
            size='xs'
            minRows={4}
            maxRows={4}
          />
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
          >
            <Button
              size='xs'
              variant='outline'
              onClick={handleCancelEditSummary}
            >
              Cancel
            </Button>
            <Button
              size='xs'
              color='blue'
              onClick={handleSaveSummary}
              loading={isSaving}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <Text size='xs'>{summary ? summary : 'No summary provided'}</Text>
      )}
    </div>
  );
};

export default TranscriptSummary;
